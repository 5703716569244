// TEAM
import Item1 from "./items/1.png";
import Item2 from "./items/2.png";
import Item3 from "./items/3.png";
import Item5 from "./items/5.png";
import Item6 from "./items/6.png";
import Item7 from "./items/7.png";
import Item8 from "./items/8.png";
import Item9 from "./items/9.png";
import Item10 from "./items/10.png";
import Item11 from "./items/11.png";
import Item12 from "./items/12.png";
import Item14 from "./items/14.png";
import Item15 from "./items/15.png";
import Item16 from "./items/16.png";
import Item17 from "./items/17.png";
import Item18 from "./items/18.png";
import Item19 from "./items/19.png";
import Unknown from "./items/unknown.png";



const Item = {
    Item1, Item2, Item3, Item5, Item6, Item7, Item8, Item9, Item10, Item11, Item12, Item14, Item15, Item16, Item17, Item18, Item19, Unknown
};

export default Item;
